import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "animate.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

/**
 * Redux Connection
 */
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import GlobalWrapper from "./components/WrapperComponents/global-wrapper";
import MemberPointSettings from "./components/WrapperComponents/MemberPointSettings";
import { TimerContextProvider } from "./components/WrapperComponents/timer_context";
import RootReducer from "./modules";
import { composeWithDevTools } from "@redux-devtools/extension";

/**
 * Language
 */
import { I18nextProvider } from "react-i18next";
import i18n from "./language/i18n";
import componentLoader from "util/componentLoader";
import "./styles/globals.css";

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

if (process.env.NODE_ENV === "production") {
}

export const store = createStore(
  RootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

let MainApp;

switch (process.env.REACT_APP_APPLICATION_CONFIG_ID) {
  // case "3":
  //   MainApp = componentLoader(() => import("./appjs/ItBabyApp"));
  //   break;
  // case "5":
  //   MainApp = componentLoader(() => import("./appjs/kkaGold"));
  //   break;
  // case "6":
  //   MainApp = componentLoader(() => import("./appjs/maymayApp"));
  //   break;
  // case "7":
  //   MainApp = componentLoader(() => import("./appjs/boraziApp"));
  //   break;
  // case "8":
  //   MainApp = componentLoader(() => import("./appjs/InnobabyApp"));
  //   break;
  // case "9":
  //   MainApp = React.lazy(() => import("./appjs/vjvApp"));
  //   break;
  // case "10":
  //   MainApp = componentLoader(() => import("./appjs/TnHApp"));
  //   break;
  // case "11":
  //   MainApp = componentLoader(() => import("./appjs/TheHouseApp"));
  //   break;
  //case "12":
  //   MainApp = componentLoader(() => import("./appjs/pyinnyarchunApp"));
  //   break;
  // case "13":
  //   MainApp = componentLoader(() => import("./appjs/medicalWorldApp"));
  //   break;
  // case '14':
  //   MainApp = componentLoader(() => import('./appjs/tyre78App'));
  //   break;
  // case "15":
  //   MainApp = componentLoader(() => import("./appjs/SkinfoodApp"));
  //   break;
  case "55":
    MainApp = componentLoader(() => import("./appjs/SkinfoodApp"));
    break;
  //case "17":
  //  MainApp = componentLoader(() => import("./appjs/uNyiLayApp"));
  //  break;

  //  case "18":
  //   MainApp = componentLoader(() => import("./appjs/MylittletoesApp"));
  //   break;
  // case "20":
  //   MainApp = componentLoader(() => import("./appjs/HibabyApp"));
  //   break;
  // case "21":
  //   MainApp = componentLoader(() => import("./appjs/ckfApp"));
  //   break;
  //case "22":
  //  MainApp = React.lazy(() => import("./appjs/OvigleApp"));
  //  break;
  // case "24":
  //   MainApp = componentLoader(() => import("./appjs/qodApp"));
  //   break;
  // case "26":
  //   MainApp = componentLoader(() => import("./appjs/mandalayfootwearApp"));
  //   break;
  // case "27":
  //   MainApp = componentLoader(() => import("./appjs/NgweohhApp"));
  //   break;
  // case "28":
  //   MainApp = componentLoader(() => import("./appjs/arteriorsApp"));
  //   break;
  // case "57":
  //   MainApp = componentLoader(() => import("./appjs/vapePiApp"));
  //   break;
  // case "32":
  //   MainApp = componentLoader(() => import("./appjs/VistraMyanmar"));
  //   break;
  // case "34":
  //   MainApp = componentLoader(() => import("./appjs/miemieplantApp"));
  //   break;
  // case "35":
  //    MainApp = componentLoader(() => import("./appjs/myancarApp"));
  //    break;
  // case "36":
  //   MainApp = componentLoader(() => import("./appjs/mypageApp"));
  //   break;
  // case "37":
  //   MainApp = React.lazy(() => import("./appjs/boeainApp"));
  //   break;
  // case "38":
  //   MainApp = componentLoader(() => import("./appjs/albeeApp"));
  //   break;
  // case "39":
  //   MainApp = componentLoader(() => import("./appjs/TwobabiesApp"));
  //   break
  // case "42":
  //   MainApp = componentLoader(() => import("./appjs/App"));
  //   break;
  // case "44":
  //   MainApp = componentLoader(() => import("./appjs/yuniEStoreApp"));
  //   break;
  // case "45":
  //   MainApp = componentLoader(() => import("./appjs/mypetApp"));
  //   break;
  // case "58":
  //   MainApp = componentLoader(() => import("./appjs/shwinlannApp"));
  //   break;
  default:
    break;
}

const queryClient = new QueryClient();
ReactDOM.render(
  // <React.StrictMode>
  <GlobalWrapper>
    <MemberPointSettings>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            {/* <PersistGate loading={<></>} persistor={persistor}> */}
            <TimerContextProvider>
              <Suspense
                fallback={<>{/* <Loading isIncludeNavbar={true} /> */}</>}
              >
                <MainApp />
              </Suspense>
            </TimerContextProvider>
            {/* </PersistGate> */}
          </QueryClientProvider>
        </Provider>
      </I18nextProvider>
    </MemberPointSettings>
  </GlobalWrapper>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
